import React from 'react'
import 'twin.macro'

export default function NewsLetter() {
    return (
<div tw="bg-white w-full">
  <div tw="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
    <div tw="px-6 py-6 bg-secondary-900 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
      <div tw="xl:w-0 xl:flex-1">
        <h2 tw="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
          Want products news and updates?
        </h2>
        <p tw="mt-3 max-w-3xl text-lg leading-6 text-secondary-200">
          Sign up for our newsletter to stay up to date.
        </p>
      </div>
      <div tw="mt-8 sm:w-full sm:max-w-xl xl:mt-0 xl:ml-8">
        <form tw="sm:flex">
          <label for="emailAddress" tw="sr-only">Email address</label>
          <input id="emailAddress" name="emailAddress" type="email" autocomplete="email" required tw="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-700 focus:ring-white rounded-md" placeholder="Enter your email" />
          <button type="submit" tw="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-secondary-500 hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
            Request a Call Back
          </button>
        </form>
        <p tw="mt-3 text-sm text-secondary-200">
          We care about the protection of your data. Read our  
          <a href="#" tw="text-white font-medium underline">
             Privacy Policy.
          </a>
        </p>
      </div>
    </div>
  </div>
</div>

    )
}
